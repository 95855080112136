import React from 'react'
import { Link } from 'gatsby'

import {
  EuiText,
  EuiSpacer,
  EuiIcon
} from '@elastic/eui'

export default function DeveloperGrid() {

  return (
    <React.Fragment>
      <EuiText size="s">
        Steuern Sie Ihre Kamera über ihre HTTP (CGI) oder MQTT API und integrieren Sie sie in Ihre Smarthome-Umgebung.
      </EuiText>
      <EuiSpacer size="m" />
      <EuiText size="s" color="subdued">
        <p>
          <Link to="/Erweitert/VPN_AVM_Fritzbox/"><EuiIcon type="grabHorizontal" /> VPN und AVM Fritzbox </Link>
          <Link to="/Erweitert/Webseiten_Einbindung/"><EuiIcon type="grabHorizontal" /> Website Integration  </Link>
          <Link to="/Erweitert/Youtube_Videostreaming_from_your_Camera/"><EuiIcon type="grabHorizontal" /> Youtube Videostreaming  </Link>
          <Link to="/Erweitert/CGI_Befehle/"><EuiIcon type="grabHorizontal" /> CGI Befehle  </Link>
          <Link to="/Erweitert/INSTAR_MQTT_Broker/"><EuiIcon type="grabHorizontal" /> INSTAR MQTT Broker  </Link>
          <Link to="/Erweitert/Homebridge_INSTAR_MQTT_to_Homekit/"><EuiIcon type="grabHorizontal" /> Homebridge & INSTAR MQTT  </Link>
          <Link to="/Erweitert/FHEM_auf_Raspberry_Pi/"><EuiIcon type="grabHorizontal" /> FHEM & Raspberry Pi  </Link>
          <Link to="/Erweitert/Node-RED_und_MQTT/"><EuiIcon type="grabHorizontal" /> Node-RED & MQTT  </Link>
          <Link to="/Erweitert/Node-RED_Dashboard_Live_Video/"><EuiIcon type="grabHorizontal" /> Node-RED Dashboard Live Video  </Link>
          <Link to="/Erweitert/Node-RED_Alarm_Event_Timeline/"><EuiIcon type="grabHorizontal" /> Node-RED Alarm Timeline  </Link>
          <Link to="/Erweitert/Alarmserver_Queries_for_your_Smarthome/"><EuiIcon type="grabHorizontal" /> Alarmserver Queries  </Link>
          <Link to="/Erweitert/Node-RED_und_SQL-Logging/"><EuiIcon type="grabHorizontal" /> Node-RED & SQL Logging  </Link>
          <Link to="/Erweitert/Node-RED_auf_Android/"><EuiIcon type="grabHorizontal" /> Node-RED auf Android  </Link>
          <Link to="/Erweitert/OpenHAB_3_in_Docker/"><EuiIcon type="grabHorizontal" /> OpenHAB 3  </Link>
          <Link to="/Erweitert/OpenHAB_3_Camera_Binding/"><EuiIcon type="grabHorizontal" /> OpenHAB 3 Camera Binding </Link>
          <Link to="/Erweitert/OpenHAB_3_MQTT_Binding/"><EuiIcon type="grabHorizontal" /> OpenHAB 3 & MQTT  </Link>
          <Link to="/Erweitert/IOBroker_auf_Raspberry_Pi/"><EuiIcon type="grabHorizontal" /> IOBroker  </Link>
          <Link to="/Erweitert/Homematic_CCU3_und_RedMatic/"><EuiIcon type="grabHorizontal" /> Homematic CCU3 a& RedMatic  </Link>
          <Link to="/Erweitert/Alexa_Echo5_and_Monocle_Gateway/"><EuiIcon type="grabHorizontal" /> Alexa & Monocle Gateway  </Link>
          <Link to="/Erweitert/IFTTT_und_INSTAR_FHD_Kameras/"><EuiIcon type="grabHorizontal" /> IFTTT & INSTAR FHD Cameras  </Link>
          <Link to="/Erweitert/IFTTT_als_Alarmserver/"><EuiIcon type="grabHorizontal" /> IFTTT als Alarmserver  </Link>
          <Link to="/Erweitert/Node-RED_und_IFTTT/"><EuiIcon type="grabHorizontal" /> Node-RED & IFTTT  </Link>
          <Link to="/Erweitert/XiaoMi_Home_Zigbee2MQTT/"><EuiIcon type="grabHorizontal" /> XiaoMi Home & Zigbee2MQTT  </Link>
          <Link to="/Erweitert/ZoneMinder_in_einem_Docker_Container/"><EuiIcon type="grabHorizontal" /> ZoneMinder & Docker  </Link>
          <Link to="/Erweitert/Tasker/"><EuiIcon type="grabHorizontal" /> Tasker auf Android <EuiIcon type="grabHorizontal" /></Link>
        </p>
      </EuiText>
    </React.Fragment>
  );
}
