import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


import openHabSearchIcon from '../../../../images/Search/AU_SearchThumb_OpenHAB.webp'
import blueIrisSearchIcon from '../../../../images/Search/P_SearchThumb_Blue_Iris.webp'
import nodeRedSearchIcon from '../../../../images/Search/AU_SearchThumb_Node-RED.webp'
import faqSearchIcon from '../../../../images/Search/FAQ_SearchThumb.webp'
import lupusSearchIcon from '../../../../images/Search/P_SearchThumb_Lupus_XT2.webp'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={openHabSearchIcon}
                    alt="OpenHAB and INSTAR IP Cameras"
                  />
                </div>
              }
              title="OpenHAB 3 Camera Widget"
              description="Erstellen Sie Ihre Kamera Erstellen Sie die Widget-Vorlage Erstellen Sie Ihre Kamera Zunächst müssen wir unsere Kamera als Gerät hinzufügen. Gehen Sie dazu zu Einstellungen und klicken Sie auf Modell: Klicken Sie auf Create Equipment from Thing:..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/OpenHAB_3_Camera_Widget/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIrisSearchIcon}
                    alt="BlueIris v5 and INSTAR IP Cameras"
                  />
                </div>
              }
              title="BlueIris v5 als HTTP Alarmserver"
              description="F: Ich sehe keine ONVIF-Trigger-Ereignisse von der Kamera zum BlueIris Server. Die Trigger werden auf der Kamera ausgelöst, Bilder werden auf der SD-Karte gespeichert, aber der Server bekommt nur den Video-/Audio-Stream ..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Frequently_Asked_Question/BlueIris_v5_http_alarmserver/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={openHabSearchIcon}
                    alt="OpenHAB and INSTAR IP Cameras"
                  />
                </div>
              }
              title="OpenHAB 3 Home Automation in Docker"
              description="Ziehen des Docker-Images Vorbereiten des Systems Ausführen des Containers Automatisieren der Docker-Einrichtung mit Ansible Wartung Aufräumen, wenn etwas schief geht OpenHAB CLI Ziehen des Docker-Images Das OpenHAB 3 ..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/OpenHAB_3_in_Docker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={openHabSearchIcon}
                    alt="OpenHAB and INSTAR IP Cameras"
                  />
                </div>
              }
              title="OpenHAB 3 INSTAR Camera Binding"
              description="Hinzufügen eines Kamera-Dings Installieren von FFMPEG Hinzufügen eines Schnappschusskanals Kamerasteuerung Hinzufügen eines Kamera-Dings Nach der Installation der Kamerabindung können wir mit dem Hinzufügen unserer Kamera fortfahren. Gehen Sie zu Things und wählen Sie das ..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/OpenHAB_3_Camera_Binding/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={openHabSearchIcon}
                    alt="OpenHAB 3 MQTT Binding"
                  />
                </div>
              }
              title="OpenHAB 3 MQTT Binding"
              description="Konfiguration der MQTT-Bindung MQTT-Client Konfiguration der MQTT-Bindung MQTT-Broker Ich beginne damit, meine INSTAR IP-Kamera als MQTT-Broker zu OpenHAB hinzuzufügen. Der Broker wird wie folgt konfiguriert..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/OpenHAB_3_MQTT_Binding/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIrisSearchIcon}
                    alt="BlueIris v5 Configure Preset Positions"
                  />
                </div>
              }
              title="BlueIris v5 Preset Positions"
              description="F: Hallo, ich habe die Kamera (Firmware 4.2.2.20) über Onvif in Blue Iris (5.3.3.8) eingebunden und möchte die voreingestellten Positionen nutzen. Die PTZ-Steuerung über Blue Iris funktioniert vollständig und mit allen o..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Frequently_Asked_Question/BlueIris_v5_preset_positions/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={nodeRedSearchIcon}
                    alt="Run a INSTAR Node-RED Dashboard in Docker"
                  />
                </div>
              }
              title="Ausführen eines INSTAR Node-RED Dashboards in Docker"
              description="Docker auf Ubuntu 20.04 installieren Node-RED-Konfiguration herunterladen Node-RED in Docker ausführen Dashboard konfigurieren Verwendete Node-RED-Knoten: Docker ist eine Anwendung, die den Prozess der Verwaltung von..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/Node-RED_in_Docker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={nodeRedSearchIcon}
                    alt="Node-RED Event Timeline für Ihre MQTT-Kamera"
                  />
                </div>
              }
              title="Node-RED Event Timeline für Ihre MQTT-Kamera"
              description="Use Case Verwendete Knoten Node-RED Flow Use Case Sie sitzen vor dem Bildschirm und müssen mit Ihrer MQTT-Kamera im Auge behalten, was irgendwo passiert. Sie möchten das Live-Video von..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Erweitert/Node-RED_Alarm_Event_Timeline/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIrisSearchIcon}
                    alt="BlueIris MQTT with Home Assistant"
                  />
                </div>
              }
              title="BlueIris MQTT with Home Assistant"
              description="MQTT Alarmserver Controlling BlueIris with MQTT Adding the Live Video MQTT Alarmserver We already configured our cameras in BlueIris to send an MQTT update once the software triggers an alarm. In my …"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Blue_Iris_v5/Home_Assistant/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIrisSearchIcon}
                    alt="BlueIris MQTT with Node-RED"
                  />
                </div>
              }
              title="BlueIris MQTT mit Node-RED"
              description="MQTT Alarmserver Steuerung von BlueIris mit MQTT JSON Flow Export Hinzufügen des Live-Videos zu Ihrem Node-RED Dashboard MQTT Alarmserver Wir haben unsere Kameras in BlueIris bereits so konfiguriert, dass sie ein MQTT-Update senden..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Blue_Iris_v5/Node-RED/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIrisSearchIcon}
                    alt="BlueIris MQTT mit ioBroker"
                  />
                </div>
              }
              title="BlueIris MQTT mit ioBroker"
              description="MQTT Alarmserver Steuerung von BlueIris mit MQTT Blockly Script Export MQTT Alarmserver Wir haben BlueIris bereits so eingerichtet, dass es ein MQTT Topic aktualisiert, wenn ein Alarm von unserer Kamera (Kurzname ) ausgelöst wird. Dieses M..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Blue_Iris_v5/ioBroker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIrisSearchIcon}
                    alt="BlueIris and INSTAR MQTT"
                  />
                </div>
              }
              title="BlueIris und INSTAR MQTT"
              description="Konfiguration des BlueIris MQTT-Dienstes MQTT-Alarmdienst Steuerung von BlueIris über MQTT-Admin-Befehle Konfiguration des BlueIris MQTT-Dienstes Zur Aktivierung des MQTT-Dienstes geben Sie die BlueIris Software..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Blue_Iris_v5/INSTAR_MQTT/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={faqSearchIcon}
                    alt="Erstellen einer Kamera-Übersichtsseite in ioBroker Vis über Node-RED"
                  />
                </div>
              }
              title="Erstellen einer Kamera-Übersichtsseite in ioBroker Vis über Node-RED"
              description="Einführung in Node-RED in ioBroker Updates von ioBroker in Node-RED empfangen ioBroker-Objekte über Node-RED UI aktualisieren Datenpunkte erstellen Node-RED Script: Alarmbereiche Node-RED-Skript: Alarmserver..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Frequently_Asked_Question/ioBroker_INSTAR_Camera_Vis_with_Node-RED/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={faqSearchIcon}
                    alt="Homebridge in Node-RED für Apple Homekit"
                  />
                </div>
              }
              title="Homebridge in Node-RED für Apple Homekit"
              description="F: Im Homebridge-Tutorial wurde gezeigt, wie man Tasten und Schalter zu Homebridge hinzufügt, so dass ich nun in der Lage bin, meine Kamera über Homebridge in meiner Homekit-App auf meinem iPhone zu steuern. Ist es möglich, die..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Frequently_Asked_Question/Homebridge_in_Node-RED/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={lupusSearchIcon}
                    alt="Lupus XT2 Plus"
                  />
                </div>
              }
              title="Lupus XT2 Plus"
              description="Hinzufügen Ihrer Kameras Live-Video Steuern der Kamera Hinzufügen Ihrer Kameras Live-Video Öffnen Sie im LUPUS XT das Menü Smarthome / Kameras: Und laden Sie die Kameraeinstellungen für die gewünschte Kamera: Kamera..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/Lupus_XT2_Plus/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
