import React from 'react'
import { Link } from 'gatsby'

import {
  EuiText,
  EuiSpacer,
  EuiIcon
} from '@elastic/eui'

export default function ProductsGrid() {

  return (
    <React.Fragment>
      <EuiText size="s">
        Alle INSTAR-Produkte - Dokumentation und Software-Downloads.
      </EuiText>
      <EuiSpacer size="m" />
      <EuiText size="s" color="subdued">
        <p>
          <Link to="/Downloadbereich/"><EuiIcon type="grabHorizontal" /> Downloadbereich  </Link>
          <Link to="/Produkte/Gebrauchsanleitungen/"><EuiIcon type="grabHorizontal" /> Handbücher  </Link>
          <Link to="/Assistants/Review_Wall/"><EuiIcon type="grabHorizontal" /> Reviews  </Link>
          <Link to="/Innenkameras/"><EuiIcon type="grabHorizontal" /> Innenkameras  </Link>
          <Link to="/Aussenkameras/"><EuiIcon type="grabHorizontal" /> Aussenkameras  </Link>
          <Link to="/Produkte/Objektive/"><EuiIcon type="grabHorizontal" /> Objektive  </Link>
          <Link to="/Web_User_Interface/1080p_Serie/Auswahl/"><EuiIcon type="grabHorizontal" /> Web User Interface  </Link>
          <Link to="/Produkte/USB-Webcams/"><EuiIcon type="grabHorizontal" /> USB Webcams  </Link>
          <Link to="/Produkte/IN-LAN/"><EuiIcon type="grabHorizontal" /> IN-LAN  </Link>
          <Link to="/Produkte/Power_over_Ethernet/"><EuiIcon type="grabHorizontal" /> Power over Ethernet  </Link>
          <Link to="/Produkte/IR_Beleuchtung/"><EuiIcon type="grabHorizontal" /> IR Scheinwerfer <EuiIcon type="grabHorizontal" /></Link>
        </p>
      </EuiText>
    </React.Fragment>
  );
}
