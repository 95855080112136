import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import webUI720SearchIcon from '../../../../images/Search/QI_SearchThumb_WebUI_720p.webp'
import webUI1080SearchIcon from '../../../../images/Search/QI_SearchThumb_WebUI_1080p.webp'
import p2pcamliveiOSSearchIcon from '../../../../images/Search/P_SearchThumb_P2P_CamLive.webp'
import ipcamviewerAndroidSearchIcon from '../../../../images/Search/P_SearchThumb_IP_Cam_Viewer.webp'
import ispyWindowsSearchIcon from '../../../../images/Search/P_SearchThumb_iSpy_Connect.webp'
import yawWindowsSearchIcon from '../../../../images/Search/P_SearchThumb_YAW_Cam.webp'
import p2pClientWindowsSearchIcon from '../../../../images/Search/P_SearchThumb_P2P_Client.webp'
import cctvExtensionSearchIcon from '../../../../images/Search/P_SearchThumb_CCTV.webp'
import blueIris3WindowsSearchIcon from '../../../../images/Search/P_SearchThumb_Blue_Iris.webp'
import loxoneSearchIcon from '../../../../images/Search/AU_SearchThumb_Loxone.webp'
import homematicSearchIcon from '../../../../images/Search/AU_SearchThumb_Homematic.webp'
import homeySearchIcon from '../../../../images/Search/P_SearchThumb_Homey.webp'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={webUI720SearchIcon}
                    alt="INSTAR IP Cameras Web User Interface"
                  />
                </div>
              }
              title="P2P Menü"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Netzwerk/P2P/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={webUI1080SearchIcon}
                    alt="Netzwerk Menü // ONVIF"
                  />
                </div>
              }
              title="ONVIF Menu"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/ONVIF/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={p2pcamliveiOSSearchIcon}
                    alt="iOS Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="P2P Cam Live"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/iOS/P2P_Cam_Live/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={ipcamviewerAndroidSearchIcon}
                    alt="Android Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="IP Cam Viewer"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Android/IP_Cam_Viewer/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={ispyWindowsSearchIcon}
                    alt="Windows Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="iSpy Connect"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/iSpy/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={yawWindowsSearchIcon}
                    alt="Windows Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="Yet Another Webcam Viewer"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/YAW_Cam/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={p2pClientWindowsSearchIcon}
                    alt="Windows Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="P2P Client"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/P2P_Client/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={cctvExtensionSearchIcon}
                    alt="Windows Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="CCTV Browser"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/CCTV_Chrome_Plugin/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIris3WindowsSearchIcon}
                    alt="Windows Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="Blue Iris v5"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Blue_Iris_v5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={loxoneSearchIcon}
                    alt="Loxone for INSTAR Cameras"
                  />
                </div>
              }
              title="Loxone"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/Loxone_Miniserver/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={homematicSearchIcon}
                    alt="Homematic CCU3 Hub"
                  />
                </div>
              }
            title="Homematic"
            footer={
              <EuiFlexGroup justifyContent="flexEnd">
                <Link to="/Software/Andere_Plattform/Homematic_IP/">
                  <EuiFlexItem grow={false}>
                    <EuiButton size="s" fill>
                      Lesen
                    </EuiButton>
                    <EuiSpacer size="s" />
                  </EuiFlexItem>
                </Link>
              </EuiFlexGroup>
            }
          />
          </EuiFlexItem>
          <EuiFlexItem>
          <EuiCard
            textAlign="left"
            image={
              <div>
                <img
                  src={homeySearchIcon}
                  alt="Homey Smarthome Gateway"
                />
              </div>
            }
            title="Homey"
            footer={
              <EuiFlexGroup justifyContent="flexEnd">
                <Link to="/Software/Andere_Plattform/Athom_Homey/">
                  <EuiFlexItem grow={false}>
                    <EuiButton size="s" fill>
                      Lesen
                    </EuiButton>
                    <EuiSpacer size="s" />
                  </EuiFlexItem>
                </Link>
              </EuiFlexGroup>
            }
          />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}