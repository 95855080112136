import React from 'react'
import { Link } from 'gatsby'

import {
  EuiText,
  EuiSpacer,
  EuiIcon
} from '@elastic/eui'

export default function InstallationGrid() {

  return (
    <React.Fragment>
      <EuiText size="s">
      Alles, was Sie wissen müssen, um mit Ihrer INSTAR IP-Kamera loszulegen - Netzwerkanschluss und grundlegende Kamerakonfiguration.
      </EuiText>
      <EuiSpacer size="m" />
      <EuiText size="s" color="subdued">
        <p>
          <Link to="/Schnell_Installation/Wie_Funktioniert_eine_IP_Kamera/"><EuiIcon type="grabHorizontal" /> Wie funktioniert eine IP Kamera?  </Link>
          <Link to="/Schnell_Installation/Nach_dem_Auspacken/"><EuiIcon type="grabHorizontal" /> Nach dem Auspacken  </Link>
          <Link to="/Schnell_Installation/Erste_Schritte/"><EuiIcon type="grabHorizontal" /> Erste Schritte  </Link>
          <Link to="/Schnell_Installation/Power_over_Ethernet/"><EuiIcon type="grabHorizontal" /> Power over Ethernet  </Link>
          <Link to="/Schnell_Installation/Powerline/"><EuiIcon type="grabHorizontal" /> Powerline  </Link>
          <Link to="/Schnell_Installation/Direkte_LAN_Verbindung/"><EuiIcon type="grabHorizontal" /> Direkte LAN Verbindung  </Link>
          <Link to="/Schnell_Installation/Alternative_IP_Scanner/"><EuiIcon type="grabHorizontal" /> Finden Sie Ihre Kamera  </Link>
          <Link to="/Schnell_Installation/Sprachauswahl/"><EuiIcon type="grabHorizontal" /> Sprachauswahl  </Link>
          <Link to="/Schnell_Installation/Live_Video/"><EuiIcon type="grabHorizontal" /> Live Video  </Link>
          <Link to="/Schnell_Installation/Benutzerverwaltung/"><EuiIcon type="grabHorizontal" /> Benutzerverwaltung  </Link>
          <Link to="/Schnell_Installation/WebUI_und_Firmware_Upgrade/"><EuiIcon type="grabHorizontal" /> Software Update  </Link>
          <Link to="/Schnell_Installation/Leeren_des_Browserverlaufs/"><EuiIcon type="grabHorizontal" /> Browsing History  </Link>
          <Link to="/Schnell_Installation/Herstellen_der_WLAN_Verbindung/"><EuiIcon type="grabHorizontal" /> WLAN Verbindung  </Link>
          {/* <Link to="/Schnell_Installation/ONVIF/"><EuiIcon type="grabHorizontal" /> ONVIF <EuiIcon type="grabHorizontal" /></Link> */}
        </p>
      </EuiText>
    </React.Fragment>
  );
}
