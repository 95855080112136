import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import webUI720SearchIcon from '../../../../images/Search/QI_SearchThumb_WebUI_720p.webp'
import webUI1080SearchIcon from '../../../../images/Search/QI_SearchThumb_WebUI_1080p.webp'
import p2pcamliveiOSSearchIcon from '../../../../images/Search/P_SearchThumb_P2P_CamLive.webp'
import ipcamviewerAndroidSearchIcon from '../../../../images/Search/P_SearchThumb_IP_Cam_Viewer.webp'
import ispyWindowsSearchIcon from '../../../../images/Search/P_SearchThumb_iSpy_Connect.webp'
import yawWindowsSearchIcon from '../../../../images/Search/P_SearchThumb_YAW_Cam.webp'
import p2pClientWindowsSearchIcon from '../../../../images/Search/P_SearchThumb_P2P_Client.webp'
import cctvExtensionSearchIcon from '../../../../images/Search/P_SearchThumb_CCTV.webp'
import blueIris3WindowsSearchIcon from '../../../../images/Search/P_SearchThumb_Blue_Iris.webp'
import loxoneSearchIcon from '../../../../images/Search/AU_SearchThumb_Loxone.webp'
import homematicSearchIcon from '../../../../images/Search/AU_SearchThumb_Homematic.webp'
import homeySearchIcon from '../../../../images/Search/P_SearchThumb_Homey.webp'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={webUI720SearchIcon}
                    alt="INSTAR IP Cameras Web User Interface"
                  />
                </div>
              }
              title="Netzwerk Menü // P2P"
              description="Der P2P-Zugang ermöglicht Ihnen den Zugriff auf den Live-Videostream Ihrer Kamera über unsere iOS- oder Android-App. Scannen Sie einfach den P2P-QR-Code mit unserer App und bestätigen Sie die Einstellungen - fertig! Bitte beachten Sie, dass der QR-Code zwar..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/720p_Serie/Netzwerk/P2P/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={webUI1080SearchIcon}
                    alt="Network Menu // ONVIF"
                  />
                </div>
              }
              title="Netzwerk Menü // ONVIF"
              description="Die Überwachungsindustrie hat ein offenes Standardprotokoll namens ONVIF geschaffen, mit dem Kameras untereinander und mit Netzwerkaufzeichnungsgeräten kommunizieren können. Sie können dieses Protokoll aktivieren oder deaktivieren..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Web_User_Interface/1080p_Serie/Netzwerk/ONVIF/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={p2pcamliveiOSSearchIcon}
                    alt="iOS Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="P2P iOS Software für Ihre INSTAR IP-Kamera"
              description="P2P Cam Live Um mit der iOS App P2P Cam Live auf Ihre IP-Kamera zugreifen zu können, empfehlen wir Ihnen, zunächst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie die Suchbegriffe P2PCam Live, um unsere App ..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/iOS/P2P_Cam_Live/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={ipcamviewerAndroidSearchIcon}
                    alt="Android Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="Android Software für Ihre INSTAR IP-Kamera"
              description="IP Cam Viewer Um mit der Android-App IP Cam Viewer auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zunächst die neueste Version aus dem App-Markt herunterzuladen. Verwenden Sie den Suchbegriff IP Cam Viewer, um unsere App zu finden..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Android/IP_Cam_Viewer/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={ispyWindowsSearchIcon}
                    alt="Windows Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="Windows-Software für Ihre INSTAR IP-Kamera"
              description="iSpy Connect iSpy nutzt Ihre Kameras, Webcams, IP-Cams und Mikrofone, um Bewegungen oder Geräusche zu erkennen und aufzuzeichnen. Erfasste Medien werden zu Flash-Video oder mp4 komprimiert und sicher über das Web und..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/iSpy/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={yawWindowsSearchIcon}
                    alt="Windows Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="Windows-Software für Ihre INSTAR IP-Kamera"
              description="Noch ein Webcam-Viewer Noch eine Webcam-Software? Vielleicht. Aber Yawcam liefert einen Schlag in einem kleinen Paket. Yawcam ist eine Webcam-Software für Windows, geschrieben in Java. Die wichtigsten Ideen für Yawcam sind,..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/YAW_Cam/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={p2pClientWindowsSearchIcon}
                    alt="Windows Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="Windows-Software für Ihre INSTAR IP-Kamera"
              description="P2P-Client Das in unserer HD-Kamera-Linie integrierte Point-2-Point-Protokoll (nicht verfügbar für VGA-Modelle) ermöglicht es Ihnen, eine Verbindung zu Ihrer Kamera aus dem Internet herzustellen, ohne dass eine Port-Weiterleitungsregel erforderlich ist..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/P2P_Client/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={cctvExtensionSearchIcon}
                    alt="Windows Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="Windows-Software für Ihre INSTAR IP-Kamera"
              description="CCTV Browser Plugin Diese Google Chrome / Opera-Erweiterung ermöglicht es Ihnen, bis zu 16 IP-Kameras oder Webcam-URLs zu definieren. Jedes Mal, wenn die Schaltfläche in der Symbolleiste angeklickt wird, wird eine aktualisierte Version des Bildes angezeigt..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/CCTV_Chrome_Plugin/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIris3WindowsSearchIcon}
                    alt="Windows Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="Windows-Software für Ihre INSTAR IP-Kamera"
              description="Blue Iris Verwenden Sie bis zu 64 Kameras, nehmen Sie JPEG-Schnappschüsse oder Filme in den Standard-Dateiformaten MP4, AVI, Advanced DVR oder Windows Media auf. Behalten Sie Ihr Zuhause, Ihren Arbeitsplatz, Ihre Autos und Wertgegenstände im Auge..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Blue_Iris_v5/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={loxoneSearchIcon}
                    alt="Loxone for INSTAR Cameras"
                  />
                </div>
              }
              title="Loxone"
              description="Homeautomation Miniserver Installation der Miniserver Smart Home App und WebUI Steuerung der INSTAR IP-Kamera über den Loxone Miniserver Beispiel 1: Auslösen eines Alarms Beispiel 2: Anfahren einer gespeicherten Position D..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/Loxone_Miniserver/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={homematicSearchIcon}
                    alt="Homematic CCU3 Hub"
                  />
                </div>
              }
              title="Homematic CCU3 Hub"
              description="Zeitgesteuerte Kameraoperationen Installieren des CUx-Daemons Erstellen einer virtuellen Schaltfläche Erstellen des Programms Verwenden der Homematic CCU3 als Alarmserver für unsere Kamera Einrichten einer Systemvariablenkonfiguration..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/Homematic_IP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={homeySearchIcon}
                    alt="Homey Smarthome Gateway"
                  />
                </div>
              }
              title="Homey Smarthome Gateway"
              description="Hinzufügen Ihrer Kameras Schnappschuss Steuern Sie Ihre Kamera manuell Benachrichtigen Sie Homey, wenn der Kamera-Alarm ausgelöst wird (Alarmserver) Verwenden Sie Zigbee/Z-Wave-Sensoren zur Steuerung Ihrer Kamera Zeitgesteuerte Kamera ..."
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Andere_Plattform/Athom_Homey/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Lesen
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
