import React from 'react'
import { Link } from 'gatsby'

import {
  EuiText,
  EuiSpacer,
  EuiIcon
} from '@elastic/eui'

export default function MotionGrid() {

  return (
    <React.Fragment>
      <EuiText size="s">
        Konfigurieren Sie Ihre Kamera so, dass sie einen Alarm auslöst, wenn eine Bewegung erkannt wird. Nehmen Sie Videos auf, benachrichtigen Sie sich per E-Mail oder Push-Nachrichten und verbinden Sie Ihre Kamera mit Ihrer Smarthome-Umgebung.
      </EuiText>
      <EuiSpacer size="m" />
      <EuiText size="s" color="subdued">
        <p>
          <Link to="/Bewegungserkennung/Einrichten/"><EuiIcon type="grabHorizontal" /> Bewegungserkennung  </Link>
          <Link to="/Bewegungserkennung/Alarm_Benachrichtigung/"><EuiIcon type="grabHorizontal" /> Alarm Benachrichtigung  </Link>
          <Link to="/Bewegungserkennung/SD_Karten_Zugriff/"><EuiIcon type="grabHorizontal" /> SD Karten Zugriff  </Link>
          <Link to="/Bewegungserkennung/Alarm_FTP_Upload/"><EuiIcon type="grabHorizontal" /> Alarm FTP Upload  </Link>
          <Link to="/Bewegungserkennung/Router_als_FTP_Server/"><EuiIcon type="grabHorizontal" /> Router als FTP Server  </Link>
          <Link to="/Bewegungserkennung/FTP_Server_Setup/"><EuiIcon type="grabHorizontal" /> FTP Server Setup  </Link>
          <Link to="/Bewegungserkennung/Alarm_Server/"><EuiIcon type="grabHorizontal" /> Alarm Server  </Link>
          <Link to="/Bewegungserkennung/INSTAR_Cloud/"><EuiIcon type="grabHorizontal" /> INSTAR Cloud <EuiIcon type="grabHorizontal" /></Link>
        </p>
      </EuiText>
    </React.Fragment>
  );
}
