import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiText,
  EuiIcon
} from '@elastic/eui'

export default function PopularGrid() {

  return (
    <React.Fragment>
    <EuiSpacer size="m" />
    <EuiText size="s" color="subdued">
      <p>
        <Link to="/Erweitert/INSTAR_MQTT_Broker/MQTT_API/"><EuiIcon type="grabHorizontal" /> Alle MQTT Topics  </Link>
        <Link to="/Erweitert/INSTAR_MQTT_Broker/MQTT_vs_HTTP_API/"><EuiIcon type="grabHorizontal" /> MQTT vs CGI Befehle  </Link>
        <Link to="/1080p_Serie_CGI_Befehle/"><EuiIcon type="grabHorizontal" /> 1080p CGI Befehle  </Link>
        <Link to="/720p_Serie_CGI_Befehle/"><EuiIcon type="grabHorizontal" /> 720p CGI Befehle  </Link>
        <Link to="/Web_User_Interface/1080p_Serie/"><EuiIcon type="grabHorizontal" /> 1080p WebUI  </Link>
        <Link to="/Web_User_Interface/720p_Serie/"><EuiIcon type="grabHorizontal" /> 720p WebUI  </Link>
        <Link to="/Software/Linux/MotionEye/"><EuiIcon type="grabHorizontal" /> MotionEye  </Link>
        <Link to="/Schnell_Installation/Alternative_IP_Scanner/"><EuiIcon type="grabHorizontal" /> IP Camera Tool  </Link>
        <Link to="/Erweitert/OpenHAB_3_Camera_Binding/"><EuiIcon type="grabHorizontal" /> OpenHAB 3 INSTAR Kamera Binding  </Link>
        <Link to="/Erweitert/Alarmserver_Queries_for_your_Smarthome/"><EuiIcon type="grabHorizontal" /> Alarmserver Queries  </Link>
        <Link to="/Bewegungserkennung/Router_als_FTP_Server/WD_MyCloud_als_FTP_Server/"><EuiIcon type="grabHorizontal" /> WD MyCloud als FTP Server  </Link>
        <Link to="/Erweitert/OpenHAB_3_MQTT_Binding/"><EuiIcon type="grabHorizontal" /> OpenHAB 3 & MQTT  </Link>
        <Link to="/Software/Windows/P2P_Client/"><EuiIcon type="grabHorizontal" /> P2P Client  </Link>
        <Link to="/Erweitert/OpenHAB_3_Camera_Widget/"><EuiIcon type="grabHorizontal" /> OpenHAB3 Kamera Widget  </Link>
        <Link to="/Software/Linux/Shinobi_Open_Source_CCTV/"><EuiIcon type="grabHorizontal" /> Shinobi Open Source CCTV  </Link>
        <Link to="/Erweitert/ZoneMinder_inside_a_Docker_Container/"><EuiIcon type="grabHorizontal" /> ZoneMinder & Docker  </Link>
        <Link to="/Erweitert/IOBroker_auf_Raspberry_Pi/motionEye/"><EuiIcon type="grabHorizontal" /> ioBroker & motionEye  </Link>
        <Link to="/Software/Linux/Node-RED/"><EuiIcon type="grabHorizontal" /> Node-RED  </Link>
        <Link to="/Software/Linux/Home_Assistant/"><EuiIcon type="grabHorizontal" /> Home Assistant  </Link>
        <Link to="/Erweitert/Youtube_Videostreaming_from_your_Camera/"><EuiIcon type="grabHorizontal" /> Youtube Videostreaming </Link>
        <Link to="/Erweitert/XiaoMi_Home_Zigbee2MQTT/"><EuiIcon type="grabHorizontal" /> XiaoMi Home Zigbee2MQTT <EuiIcon type="grabHorizontal" /></Link>
        </p>
      </EuiText>
    </React.Fragment>
  );
}
