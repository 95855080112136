import React from 'react'
import { Link } from "gatsby"

import {
  EuiIcon,
  EuiTabbedContent
} from '@elastic/eui'

import IntroHome from 'components/Index/Frontpage/IntroHome'
import IntroLatest from 'components/Index/Frontpage/IntroLatest'
import IntroPopular from 'components/Index/Frontpage/IntroPopular'
import IntroSmarthome from 'components/Index/Frontpage/IntroSmarthome'

import SEOHelmet from 'components/Layout/SEOHelmet'
import BreadCrumbs from 'components/Layout/BreadCrumbs'


const seodata = {
  title: 'INSTAR Wiki :: Wissensdatenbank für IP Kameras und Hausautomatisierung',
  description: 'Fragen zu Ihren Kameras, der Netzwerktechnik oder der Smart-System-Integration? Hier bieten wir fachkundige Anleitungen rund um IP Kameras und smarte Integrationsmöglichkeiten an.',
  image: '/images/Search/QI_SearchThumb_SetupWizard.png',
  twitter: '/images/Search/QI_SearchThumb_SetupWizard.webp',
  locationEN: 'https://wiki.instar.com/en/',
  locationDE: 'https://wiki.instar.com/de/',
  locationFR: 'https://wiki.instar.com/fr/'
}


export default function FrontGrid(props) {
    const tabs = [
      {
        id: 'home--id',
        name: (
          <span>
            <EuiIcon type="home" />
            &nbsp;Home
          </span>
        ),
        content: (
          <IntroHome />
        ),
      },
      {
        id: 'latest--id',
        name: (
          <span>
            <EuiIcon type="heatmap" />
            &nbsp;Aktuell
          </span>
        ),
        content: (
          <IntroLatest />
        ),
      },
      {
        id: 'popular--id',
        name: (
          <span>
            <EuiIcon type="cheer" />
            &nbsp;Populär
          </span>
        ),
        content: (
          <React.Fragment>
            <IntroPopular />
          </React.Fragment>
        ),
      },
      {
        id: 'smarthome--id',
        name: (
          <span>
            <EuiIcon type="indexMapping" />
            &nbsp;Smarthome
          </span>
        ),
        content: (
          <React.Fragment>
            <IntroSmarthome />
          </React.Fragment>
        ),
      },
      {
        id: 'search_link',
        name: (
          <Link to="/Search/">
            <span>
              <EuiIcon type="search" />
              &nbsp;Erweiterte Suche
            </span>
          </Link>
        ),
        content: (
          <React.Fragment>
            <div></div>
          </React.Fragment>
        ),
        disabled: false,
      },
    ];

  return (
    <React.Fragment>

        <SEOHelmet
          title={seodata.title}
          description={seodata.description}
          image={seodata.image}
          location={props.location.pathname} />

        <BreadCrumbs
            locationBC={props.location}
            locationDE={props.location.pathname}
            locationEN='/en/'
            locationFR='/fr/'
            crumbLabel="Home" />

        <EuiTabbedContent
          tabs={tabs}
          initialSelectedTab={tabs[0]}
          autoFocus="selected"
          style={{ padding: 10 }}
        />

    </React.Fragment>
  );
}