import React from 'react'
import { Link } from 'gatsby'

import {
  EuiText,
  EuiSpacer,
  EuiIcon
} from '@elastic/eui'

export default function SoftwareGrid() {

  return (
    <React.Fragment>
      <EuiText size="s">
        INSTAR-Softwarelösungen und Fremdsoftware für Ihre INSTAR-Kamera.
      </EuiText>
      <EuiSpacer size="m" />
      <EuiText size="s" color="subdued">
        <p>
          <Link to="/Software/Windows/"><EuiIcon type="grabHorizontal" /> Software für Windows  </Link>
          <Link to="/Software/macOS/"><EuiIcon type="grabHorizontal" /> Software für macOS  </Link>
          <Link to="/Software/Linux/"><EuiIcon type="grabHorizontal" /> Software für Linux  </Link>
          <Link to="/Software/Android/"><EuiIcon type="grabHorizontal" /> Software für Android  </Link>
          <Link to="/Software/iOS/"><EuiIcon type="grabHorizontal" /> Software für iOS  </Link>
          <Link to="/Software/Smarthome/"><EuiIcon type="grabHorizontal" /> Software für Smarthomes </Link>
          <Link to="/Software/Andere_Plattform/"><EuiIcon type="grabHorizontal" /> Software für andere Plattformen <EuiIcon type="grabHorizontal" /></Link>
        </p>
      </EuiText>
    </React.Fragment>
  );
}
