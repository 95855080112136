import React from 'react'

import {
  EuiTitle,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiHideFor
} from '@elastic/eui'

import PopularCards from './Popular/IntroPopularArticles'
import PopularCardsMobile from './Popular/IntroPopularArticles_mobile'

export default function IntroHome() {

  return (
    <React.Fragment>
      <EuiSpacer />
        <EuiTitle size="s">
            <h3>Populäre Artikel</h3>
        </EuiTitle>
        <EuiHorizontalRule margin="xs" />
        <EuiFlexGroup gutterSize="l" direction="column" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiHideFor sizes={['xs', 's', 'm', 'l']}>
              <PopularCards />
            </EuiHideFor>
            <EuiHideFor sizes={['xl']}>
              <PopularCardsMobile />
            </EuiHideFor>
          </EuiFlexItem>
    </EuiFlexGroup>
  </React.Fragment>
  );
}
