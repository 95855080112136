import React from 'react'
import { Link } from 'gatsby'

import {
  EuiText,
  EuiSpacer,
  EuiIcon
} from '@elastic/eui'

export default function RemoteGrid() {

  return (
    <React.Fragment>
      <EuiText size="s">
        Zugriff auf Ihre Kamera über das Internet - dynamischer DNS und Point2Point-Verbindungsaufbau.
      </EuiText>
      <EuiSpacer size="m" />
      <EuiText size="s" color="subdued">
        <p>
          <Link to="/Fernzugriff/Point_to_Point/"><EuiIcon type="grabHorizontal" /> P2P Point-2-Point  </Link>
          <Link to="/Fernzugriff/Der_DDNS_Dienst/"><EuiIcon type="grabHorizontal" /> INSTAR DDNS  </Link>
          <Link to="/Fernzugriff/Port_Weiterleitung/"><EuiIcon type="grabHorizontal" /> Port Weiterleitung  </Link>
          <Link to="/Fernzugriff/Mobil_Zugriff/"><EuiIcon type="grabHorizontal" /> Mobiler Zugriff  </Link>
          <Link to="/Fernzugriff/DDNS_Anbieter/"><EuiIcon type="grabHorizontal" /> DDNS Anbieter <EuiIcon type="grabHorizontal" /></Link>
        </p>
      </EuiText>
    </React.Fragment>
  );
}
